import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import PropTypes from 'prop-types'
import styles from './footer.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
   faPhoneAlt,
   faConciergeBell,
   faEnvelope,
   faCommentAlt,
} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import logo from '../assets/images/logo-white.svg'

const Footer = ({ siteTitle }) => (
   <footer className={styles.footer}>
      <div className={styles.footer__primary}>
         <div className={styles.footer__inner}>
            <section
               className={[
                  styles.footer__column,
                  styles.column__large,
                  styles.footer__intro_column,
               ].join(' ')}>
               <Link
                  to="/"
                  className={styles.footer__logo}
                  aria-label="Home Page">
                  <img src={logo} alt="" />
               </Link>
               <div className={styles.footer__spacer_half} />
               <p className={styles.footer__slogan}>
                  We provide simple solutions to complex financial problems.{' '}
                  <b>
                     Finance on a human level<sup>&reg;</sup>
                  </b>
               </p>

               <div className={styles.footer__spacer} />

               <h4 className={styles.column__title}>Corporate Office</h4>
               <Link to="/contact/" className={styles.footer__link}>
                  <span className={styles.column__row}>
                     3202 Tower Oaks Blvd, Ste 400
                  </span>
                  <span className={styles.column__row}>
                     Rockville, MD, USA 20852
                  </span>
               </Link>

               <div className={styles.footer__spacer} />

               <div className={styles.footer__help}>
                  <ul className={styles.help__grid}>
                     <li>
                        <a href="tel:+13017706800" rel="noopener noreferrer">
                           <FontAwesomeIcon
                              className={styles.icon}
                              icon={faPhoneAlt}
                              fixedWidth
                           />
                           <span className={styles.label}>Call</span>
                        </a>
                     </li>

                     <li>
                        <Link to="/faq">
                           <FontAwesomeIcon
                              className={styles.icon}
                              icon={faConciergeBell}
                              fixedWidth
                           />
                           <span className={styles.label}>FAQs</span>
                        </Link>
                     </li>

                     <li>
                        <OutboundLink
                           href="//linkedin.com/company/spc-financial-inc"
                           rel="noopener noreferrer"
                           target="_blank">
                           <FontAwesomeIcon
                              className={styles.icon}
                              icon={faLinkedin}
                              fixedWidth
                           />
                           <span className={styles.label}>LinkedIn</span>
                        </OutboundLink>
                     </li>

                     <li>
                        <Link to="/contact/">
                           <FontAwesomeIcon
                              className={styles.icon}
                              icon={faEnvelope}
                              fixedWidth
                           />
                           <span className={styles.label}>Email</span>
                        </Link>
                     </li>

                     <li>
                        <a href="#">
                           <FontAwesomeIcon
                              className={styles.icon}
                              icon={faCommentAlt}
                              fixedWidth
                           />
                           <span className={styles.label}>Chat</span>
                        </a>
                     </li>
                  </ul>
               </div>
            </section>

            <section
               className={[
                  styles.footer__column,
                  styles.column__large,
                  styles.column__information,
               ].join(' ')}>
               <h4 className={styles.column__title}>Business Hours</h4>
               <p className={styles.footer__text}>Monday - Friday</p>
               <p className={styles.footer__text}>8:30 AM - 5:30 PM</p>

               <h4 className={styles.column__title_margin}>Phone</h4>
               <OutboundLink
                  href="tel:+13017706800"
                  rel="noopener noreferrer"
                  className={styles.footer__link}>
                  (301) 770&#45;6800
               </OutboundLink>

               <h4 className={styles.column__title_margin}>Fax</h4>
               <p
                  href="fax:+13017709031"
                  rel="noopener noreferrer"
                  className={styles.footer__text}>
                  (301) 770&#45;9031
               </p>

               <h4 className={styles.column__title_margin}>Email</h4>
               <Link to="/contact/" className={styles.footer__link}>
                  info@spcfinancial.com
               </Link>
            </section>
            <section
               className={[styles.footer__column, styles.column__legal].join(
                  ' '
               )}>
               <div className={styles.legal__links}>
                  <h4 className={styles.column__title}>Legal</h4>
                  <Link to="/legal/terms" className={styles.footer__link}>
                     Terms of Use
                  </Link>
                  <Link to="/legal/privacy" className={styles.footer__link}>
                     Privacy Policy
                  </Link>
                  <OutboundLink
                     href="//brokercheck.finra.org/"
                     rel="noopener noreferrer"
                     target="_blank"
                     className={styles.footer__link}>
                     FINRA's Broker Check
                  </OutboundLink>
                  <Link
                     to="/legal/disclaimers/"
                     className={styles.footer__link}>
                     Disclaimers + Disclosures
                  </Link>
                  <OutboundLink
                     href="https://adviserinfo.sec.gov/firm/summary/104618"
                     rel="noopener noreferrer"
                     target="_blank"
                     className={styles.footer__link}>
                     Investment Adviser Public Disclosure
                  </OutboundLink>
               </div>

               <p
                  className={[
                     styles.footer__text,
                     styles.footer__disclaimer,
                  ].join(' ')}>
                  &copy; {new Date().getFullYear()} {siteTitle} All Rights
                  Reserved.
               </p>

               <p
                  className={[
                     styles.footer__text,
                     styles.footer__disclaimer,
                  ].join(' ')}>
                  Investment advisory services offered through SPC.
                  <br />
                
                  <br />
                  *See{' '}
                  <Link to="/legal/disclaimers#tax">
                     Disclaimers + Disclosures
                  </Link>
               </p>
            </section>
         </div>
      </div>
      <div className={styles.footer__tertiary}>
         <div className={styles.footer__inner}>
            <Link to="/" className={styles.footer__nav_link}>
               Home
            </Link>
            <Link to="/about/" className={styles.footer__nav_link}>
               About
            </Link>
            <Link to="/team/" className={styles.footer__nav_link}>
               Team
            </Link>
            <Link to="/rankings/" className={styles.footer__nav_link}>
               Rankings
            </Link>
            <Link to="/faq/" className={styles.footer__nav_link}>
               FAQ
            </Link>
            <Link to="/clients/" className={styles.footer__nav_link}>
               Clients
            </Link>
            <Link to="/contact/" className={styles.footer__nav_link}>
               Contact
            </Link>
         </div>
      </div>
   </footer>
)

Footer.propTypes = {
   siteTitle: PropTypes.string,
}

Footer.defaultProps = {
   siteTitle: ``,
}

export default Footer
