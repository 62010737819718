import React, { Component } from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import styles from './tool.module.sass'

import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
   faLongArrowAltRight,
   faQuestionCircle,
   faCheckCircle,
   faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

let none = 'none'

class InsightsPage extends Component {
   constructor(props) {
      super(props)

      this.setState = this.setState.bind(this)
   }

   popup(title, content, type) {
      Swal.fire(`${title}`, `${content}`, `${type}`)
   }

   render() {
      return (
         <Layout>
            <SEO title="Insights by SPC Financial&reg;" />
            <div className={['page', styles.page__tool].join(' ')}>
               <div className={['page--header', styles.page__header].join(' ')}>
                  <h1 className={['page--title', styles.page__title].join(' ')}>
                     Insights <small>by SPC Financial&reg;</small>
                  </h1>
                  {/* <p className={['page--subheader', styles.page__subheader].join(' ')}></p> */}
               </div>
               <div className={styles.tool__content}>
                  <div className={styles.tool__intro}>
                     <div className={styles.left}>
                        <p>
                           Insights allows you to view your{' '}
                           <b>comprehensive financial picture</b> by aggregating
                           and organizing all of your financial accounts in one
                           place and accessing them using{' '}
                           <b>one secure log in</b>. It is as simple as linking
                           accounts from various institutions to your Insights
                           account.
                        </p>
                        <p>
                           With Insights, you can <b>track spending</b>,{' '}
                           <b>create a budget</b>, <b>store documents</b> in a
                           secured vault, and{' '}
                           <b>review your investment holdings</b> and overall
                           allocations.
                        </p>
                     </div>
                     <div className={styles.right}>
                        <OutboundLink
                           href="//wealth.emaplan.com/ema/SignIn?ema%2fraymondjames%2fspcfinancial"
                           target="_blank"
                           rel="noopener noreferrer"
                           className="button button--primary button--large button--has-icon">
                           Continue to Your{' '}
                           <span style={{ display: none }}>Insights</span>{' '}
                           Dashboard{' '}
                           <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </OutboundLink>
                     </div>
                  </div>

                  {/* <div className={styles.tool__comparison}>
                     <table>
                        <thead>
                           <tr>
                              <th className={styles.hide}></th>
                              <th>Insights</th>
                              <th>Client Access</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td>Accounts</td>
                              <td className={styles.positive}>
                                 All
                                 <p>
                                    Includes:
                                    <br />
                                    <b>assets + liabilities</b>,{' '}
                                    <b>insurance products</b>
                                 </p>
                              </td>
                              <td className={styles.neutral}>
                                 Limited
                                 <p>RJ accounts only</p>
                              </td>
                           </tr>
                           <tr>
                              <td>Real-time balances</td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>If accounts are linked</p>
                              </td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>If accounts are linked</p>
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 Secure vault
                                 <small
                                    onClick={() =>
                                       this.popup(
                                          'Secure Vault',
                                          'Transmit documents securely to our office and store important documents securely to be accessed anywhere with an internet connection.',
                                          'info'
                                       )
                                    }>
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                 </small>
                              </td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                              </td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                              </td>
                           </tr>
                           <tr>
                              <td>Bill pay</td>
                              <td className={styles.negative}>
                                 <FontAwesomeIcon icon={faTimesCircle} />
                              </td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>Via Capital Access account</p>
                              </td>
                           </tr>
                           <tr>
                              <td>Market research</td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                              </td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                              </td>
                           </tr>
                           <tr>
                              <td>Notifications preferences</td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>
                                    Configure email alerts for large
                                    expenses/deposits, etc.
                                 </p>
                              </td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>
                                    Configure statement/document delivery
                                    preferences
                                 </p>
                              </td>
                           </tr>
                           <tr>
                              <td>Reports</td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>
                                    Net worth statements, asset allocation,
                                    holding details, etc.
                                 </p>
                              </td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>Realized Gain/Loss</p>
                              </td>
                           </tr>
                           <tr>
                              <td>Document retrieval</td>
                              <td className={styles.negative}>
                                 <FontAwesomeIcon icon={faTimesCircle} />
                              </td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>
                                    Obtain RJ statements, tax docs, trade
                                    confirmations + prospectuses
                                 </p>
                              </td>
                           </tr>
                           <tr>
                              <td>Budget + spending tools</td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                              </td>
                              <td className={styles.negative}>
                                 <FontAwesomeIcon icon={faTimesCircle} />
                              </td>
                           </tr>
                           <tr>
                              <td>Cash management</td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                              </td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>
                                    Review info on direct deposits, checks,
                                    ACHs, etc.
                                 </p>
                              </td>
                           </tr>
                           <tr>
                              <td>Account analysis</td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>
                                    View investment summaries, asset allocations
                                    + transaction reports
                                 </p>
                              </td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>Value over time, asset allocation</p>
                              </td>
                           </tr>
                           <tr>
                              <td>Reviewing activity</td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>Transactions related to linked accounts</p>
                              </td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>Pending/posted activity (RJ accounts)</p>
                              </td>
                           </tr>
                           <tr>
                              <td>Retirement account details</td>
                              <td className={styles.negative}>
                                 <FontAwesomeIcon icon={faTimesCircle} />
                              </td>
                              <td className={styles.positive}>
                                 <FontAwesomeIcon icon={faCheckCircle} />
                                 <p>
                                    Review beneficiary designations,
                                    contribution info and federal + state tax
                                    withholding elections
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div> */}

                  <div className={styles.tool__disclaimer}>
                     <p>
                        eMoney Advisor, LLC (eMoney) provides the platform for
                        Insights by SPC Financial<sup>&reg;</sup>.                     
                     </p>
                  </div>
               </div>
            </div>
         </Layout>
      )
   }
}

export default InsightsPage
