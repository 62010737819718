import React from 'react'
import styles from './announcement.module.sass'
import { faqs } from '../announcement-faqs'

import {
   Accordion,
   AccordionItem,
   AccordionItemHeading,
   AccordionItemButton,
   AccordionItemPanel,
} from 'react-accessible-accordion'

import Layout from '../components/layout'
import SEO from '../components/seo'

const FaqPage = () => (
   <Layout>
      <SEO
         title="SPC Financial, Inc. and Sella & Martinic, LLC Announce Congeneric
               Combination"
      />
      <div className={['page', styles.page__new_client].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               SPC Financial, Inc. and Sella & Martinic, LLC Announce Congeneric
               Combination
            </h1>
            <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
               We are writing to share exciting news that represents a natural
               evolution of our twenty-five years affiliation. Effective January
               1, 2025, Sella & Martinic, LLC is combining its business with SPC
               Financial, Inc. to create a fully integrated, tax-efficient
               wealth management experience. <br></br> <br></br> As you may
               know, our firms serve many mutual clients. We are now moving the
               tax practice of Sella & Martinic into SPC Financial® to allow us
               to deliver better integrated services. <br></br> <br></br> For
               those of you who already work with both SPC Financial® and Sella
               & Martinic, this combination will streamline your experience as
               the trusted professionals of both businesses will work as one
               team. <br></br> <br></br> For Sella & Martinic clients, this
               combination provides an opportunity to access additional services
               through multi-disciplinary, tax-integrated, human-centric wealth
               management professionals. While there is no current obligation to
               expand your relationship, we would welcome the opportunity to
               discuss how our combined capabilities might benefit your specific
               situation. <br></br> <br></br> Together, our services will
               include wealth preservation, portfolio management, college
               funding, income tax preparation, income and estate tax planning,
               and retirement planning. You may view our investment advisory,
               management and financial planning services at spcfinancial.com.
            </p>
         </div>{' '}
         <div className={styles.content}>
            <h2>Frequently Asked Questions</h2>
            {faqs.map(
               (faq, i) =>
                  faq.isActive && (
                     <Accordion
                        key={i}
                        allowZeroExpanded="true"
                        className={styles.accordion}>
                        <AccordionItem className={styles.accordion__item}>
                           <AccordionItemHeading>
                              <AccordionItemButton
                                 className={styles.accordion__button}>
                                 <span>{faq.question}</span>
                              </AccordionItemButton>
                           </AccordionItemHeading>
                           <AccordionItemPanel
                              className={styles.accordion__panel}>
                              <div className={styles.answer}>
                                 {faq.answer.map((a, i) => (
                                    <p key={i}>{a}</p>
                                 ))}
                              </div>
                           </AccordionItemPanel>
                        </AccordionItem>
                     </Accordion>
                  )
            )}
            <h2>Next Steps</h2>
            <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
               In the coming weeks, you will receive additional information from
               SPC Financial® and Sella & Martinic about this transition. Until
               then, please continue to work with your tax advisor and our team
               as usual. All existing services, fees, and arrangements will
               remain unchanged unless you choose to modify them. <br></br>
               <br></br> As always, SPC Financial® remains committed to
               providing you human-centric service and professional guidance. If
               you have any questions, please don’t hesitate to contact your tax
               advisor or Sella & Martinic’s office at 301-770-0200. <br></br>
               <br></br> Thank you for your continued trust. We look forward to
               building upon our strong foundation to serve you even better in
               the coming years.
            </p>
            <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
               Sincerely, <br></br>
               <br></br> <b> Edward G. Sella, CPA/PFS, CFP®</b> <br></br>{' '}
               President & CEO, SPC Financial, Inc. <br></br> Partner, Sella &
               Martinic, LLC <br></br>
               <br></br> <b>Leo D. Martinic, CPA</b> <br></br> Financial
               Advisor, SPC Financial, Inc. <br></br> Partner, Sella & Martinic,
               LLC
            </p>
         </div>
      </div>
   </Layout>
)

export default FaqPage
