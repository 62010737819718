export const faqs = [
    {
       id: 1,
       question: 'Why is this business transaction taking place?',
       answer: [
          "Following our custodial transition from Raymond James to Fidelity, SPC Financial® has greater independence to provide tax services directly to our clients, previously frowned upon in our Raymond James relationship. Also, many of our team members have had dual employment with and responsibilities to both SPC Financial® and Sella & Martinic.  Under our new arrangement, all of our team members will be employed by and work only within SPC Financial®.",
       ],
       isActive: true,
    },
    {
       id: 2,
       question: 'How will this affect the preparation and filing of my 2024 tax return?',
       answer: [
          "The tax preparation process will be similar to your previous experience. Notably, because we no longer operate a branch office of Raymond James and have transitioned our clients to Fidelity, we cannot directly access your Raymond James account documents, including your annual account statements and forms 1099.  The 1099 forms will need to be provided by you to us, either by mail, in-person drop-off, or uploaded to your SPC vault (if applicable).",
       ],
       isActive: true,
    },
    {
       id: 3,
       question:
          'Will Sella & Martinic’s services or fees be changing?',
       answer: [
          'Even though SPC Financial® will be the entity preparing your tax returns and managing the tax and accounting fees, our plan is to keep in place the principal fee arrangements you have had with Sella & Martinic unless your scope of services is changed.',
       ],
       isActive: true,
    },
    {
       id: 4,
       question: 'What do I need to do?',
       answer: [
          'Currently, no action is required by you. We will be in touch over the coming weeks. SPC Financial® will provide a welcome packet with an updated client engagement agreement and tax organizer for your completion on or before January 15, 2025.',
       ],
       isActive: true,
    },
    {
       id: 5,
       question: 'Who can I contact if I have questions?',
       answer: [
          'Please don’t hesitate to contact your tax advisor or Sella & Martinic’s office at 301-770-0200. We are available and prepared to address any concerns.',
       ],
       isActive: true,
    },
   
 ]
 
 export default {
    faqs,
 }
 